import React, { Suspense, } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import './scss/style.scss'
import {
  CToaster,
  CToast,
  CToastBody,
  CToastClose,
} from "@coreui/react-pro";

const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

function App() {
  const dispatch = useDispatch();

  const toastMessage = useSelector((state) => state.toastMessage);

  const loading = (
    <div className="pt-3 text-center">
      <div className="sk-spinner sk-spinner-pulse"></div>
    </div>
  )

  return (
    <BrowserRouter>
      <CToaster placement="top-end">
        <CToast
          autohide={true}
          animation={true}
          visible={toastMessage.isShown}
          color={toastMessage.color}
          className="text-white align-items-center"
          onClose={() => {
            dispatch({
              type: "SET_TOAST_MESSAGE",
              toastMessage: {
                isError: false,
                isShown: false,
                color: "primary",
                text: "",
              },
            });
          }}
        >
          <div className="d-flex">
            <CToastBody>{toastMessage.text}</CToastBody>
            <CToastClose className="me-2 m-auto" white />
          </div>
        </CToast>
      </CToaster>
      <Suspense fallback={loading}>
        <Routes>
          <Route exact path="/login" name="Login Page" element={<Login />} />
          <Route exact path="/register" name="Register Page" element={<Register />} />
          <Route exact path="/404" name="Page 404" element={<Page404 />} />
          <Route exact path="/500" name="Page 500" element={<Page500 />} />
          <Route path="*" name="Home" element={<DefaultLayout />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  )
}

export default App
